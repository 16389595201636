import * as React from "react";
import { useEffect } from 'react';
import "./layout/styles.scss";
import {
  SEOMetaTags,
  TitleSection,
  BlogBreadcrumbs,
  CallToAction,
  GreyFooter,
  FundingBanner,
  InterviewCard,
  NextButton,
  PrevButton
} from "../components";
import MainMenu from "../components/MainMenu/MainMenu";

const InterviewList = (data: any): JSX.Element => {
  useEffect(() => {
    const bodyStyle = document.body.style;
    bodyStyle.opacity = "1";
    bodyStyle.transition = "opacity 0.3s";
  }, []);

  let { hasNextPage, hasPrevPage, pageName, prevPage, nextPage, items, seoMetaTags, menu, footer, lang, urlName, cta, c2c, titleHeader, titleDescription, banner } = data.pageContext;
  const breadcrumbsPages = [{ key: "page", value: pageName, url: `${ lang }/${ urlName }/1` }];

  const interviews = items.filter(item => item.interviewTitle && item.interviewDescription)
  return <>
    <MainMenu menu={ menu }
              lang={ lang }
              urlName={ urlName }
              c2c={ c2c }/>
    <SEOMetaTags lang={ lang } tags={ seoMetaTags } pageName={ pageName }/>
    <TitleSection propertyReference={ [{ key: "title", value: titleHeader }, { key: "blog", value: "blog-version" }, {
      key: "color",
      value: "green-version",
    }, { key: "paragraph", value: `${ titleDescription }` }] }/>
    <section className="subpage-section">
      <div className="content-limiter">
        <BlogBreadcrumbs pages={ breadcrumbsPages }/>
        <div className="cards-grid">
          { interviews.map(interview => <InterviewCard key={ Math.random() + new Date().toDateString() }
                                                       lang={ lang }
                                                       { ...interview }/>) }
        </div>
        <nav>
          { hasPrevPage && <PrevButton lang={ lang } path={ `/${ lang }/${ urlName }/${ prevPage }` }/> }
          { hasNextPage && <NextButton lang={ lang } path={ `/${ lang }/${ urlName }/${ nextPage }` }/> }
        </nav>
      </div>
    </section>
    <CallToAction cta={ cta }/>
    <GreyFooter footer={ footer }
                lang={ lang }/>
    <FundingBanner banner={ banner }/>
  </>
}

export default InterviewList;
